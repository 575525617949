import React from 'react';
import logo from '../logo.png';

const Home = () => {
  return (
    <main className="mx-auto text-center">
      <div className="alert alert-info h3 m-2 p-3">
        Coming soon. Please bear with us.
      </div>
      <img src={logo} alt={'Lightfoot Dancing logo'} />
    </main>
  );
};

export default Home;
