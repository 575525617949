export const addToHomescreen = () => {
  return new Promise((resolve, reject) => {
    window.addEventListener('beforeinstallprompt', (event) => {
      // Prevent Chrome 67 and earlier from automatically showing the prompt
      event.preventDefault();
      // Stash the event so it can be triggered later.
      resolve(event);
    });
  }).then((installer) => {
    installer.prompt();
  }).catch(reason => {
    console.log(reason);
  });
};
