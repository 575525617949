import React from 'react'
import { Redirect, Route } from 'react-router-dom'
import * as Screens from './screens'
//import { isUserLoggedIn } from './services/auth'

const isUserLoggedIn = () => false;

const PrivateRoute = ({ component: Component, ...params }) => (
  <Route
    {...params}
    render={props => (
      isUserLoggedIn()
        ? <Component {...props} />
        : <Redirect to='/login' />
    )}
  />
);

export default [
  <Route key={1} path='/login' component={Screens.Login} />,
  <Route key={2} path='/' component={Screens.Home} />,
  <PrivateRoute key={3} exact path='/dashboard' component={Screens.Dashboard} />,
];
