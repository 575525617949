import React from 'react';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import routes from './routes';
//import logo from '../public/logo.svg';
import './App.css';
import 'react-toastify/dist/ReactToastify.css';

const ModalContainer = () => (<div id="modal-root" />);

const App = () => (
  <>
    <Router>
      <Switch>{routes}</Switch>
    </Router>
    <ModalContainer />
    <ToastContainer
      autoClose={8000}
      style={{ textAlign: 'center' }}
    />
  </>
);

/*function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.js</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
      </header>
    </div>
  );
}*/

export default App;
